import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-scroll';
import LangDropdown from './LangDropdown';
import ProductsDropdown from './ProductsDropdown';
import ProductsDropdownMobile from './ProductsDropdownMobile';

const Header = props => {
  const {
    lang,
    data: {
      price,
      docs,
      nav,
      product,
      products,
      img,
      logoImg,
      sign: {
        signIn,
        register,
        signUp
      },
      arrows
    },
    mobileOpen,
    onMobileOpen,
  } = props;

  const navClasses = cn({
    nav: true,
    active: mobileOpen
  });

  const burgerClasses = cn({
    burger: true,
    active: mobileOpen
  });

  const onLinkClick = () => {
    if (mobileOpen) {
      onMobileOpen();
    }
  }

  const appURL = process.env.REACT_APP_URL.split('//');
  const signInURL = `${appURL[0]}//app.${appURL[1]}/login?lang=${lang}`;
  const signUpURL = `${appURL[0]}//app.${appURL[1]}/register?lang=${lang}`;

  const firstNav = nav && nav[0];
  const otherNavs = nav && nav.slice(1);

  return (
    <header className='header'>
      <div className='container'>
        <a className='logo' href='/' >
          <img src={ logoImg.src } alt={ logoImg.alt } />
        </a>
        <nav className={ navClasses }>
          <ul className='nav__list'>
            {firstNav && (
              <li key={ firstNav.text } className='nav__item'>
                <Link
                  className='nav__link'
                  to={ firstNav.to }
                  smooth={ true }
                  duration={ 500 }
                  offset={Number(firstNav.offset) || 0}
                  onClick={onLinkClick}
                >
                  { firstNav.text }
                  <span></span>
                </Link>
              </li>
            )}
            <ProductsDropdown product={product} products={products} />
            <ProductsDropdownMobile product={product} products={products} onLinkClick={onLinkClick} />
            { otherNavs.map(item => (
              <li key={ item.text } className='nav__item'>
                <Link
                  className='nav__link'
                  to={ item.to }
                  smooth={ true }
                  duration={ 500 }
                  offset={Number(item.offset) || 0}
                  onClick={onLinkClick}
                >
                  { item.text }
                  <span></span>
                </Link>
              </li>
            )) }
            {price &&
              <li className="nav__item">
                <Link
                  className='nav__link'
                  to='pricing'
                  smooth
                  duration={ 500 }
                  onClick={onLinkClick}
                >
                  {price}
                  <span></span>
                </Link>
              </li>
            }
            {docs &&
            <li className="nav__item">
              <a href="https://docs.theairr.com" className="nav__link" >
                { docs }
              </a>
            </li>
            }
            <li className="nav__item mob">
              <LangDropdown lang={lang} mobile />
            </li>
          </ul>
          <div className='logo-burger mob'>
            <img src={ img.src } alt={ img.alt } />
          </div>
          <div className={`buttons-mob buttons-mob-down`}>
            <a
              className='mob login-mob js-login'
              href={ signInURL }
            >
              { signIn.text }
            </a>
            <a
              className='mob register-mob js-register'
              href={ signUpURL }
            >
              { register.text }
            </a>
          </div>
        </nav>
        <div className='lk-wrap'>
          <LangDropdown lang={lang} />
          <a
            className='tablet head-btn sign-in lock-icon'
            href={ signInURL }
          >
            { signIn.text }
            <span />
          </a>
          <a
            className='tablet head-btn sign-up active'
            href={ signUpURL }
          >
            { signUp.text }
            { arrows.map(item => (
              <span key={ item }>
                <img src={ item } alt='' />
              </span>
            )) }
          </a>
        </div>
        <span
          className={ burgerClasses }
          role='presentation'
          onClick={ onMobileOpen }
        >
          <span className='burger__line burger__line_top' />
          <span className='burger__line burger__line_middle' />
          <span className='burger__line burger__line_bottom' />
        </span>
      </div>
    </header>
  );
};

Header.propTypes = {
  lang: PropTypes.string,
  data: PropTypes.shape({
    nav: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.string
      })
    ),
    price: PropTypes.string,
    img: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    }),
    sign: PropTypes.shape({
      signIn: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.string
      }),
      register: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.string
      }),
      signUp: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.string
      }),
    })
  }),
  mobileOpen: PropTypes.bool,
  onRegisterOpen: PropTypes.func,
  onLoginOpen: PropTypes.func,
  onMobileOpen: PropTypes.func
};

Header.defaultProps = {
  lang: '',
  data: {},
  mobileOpen: false
};

export default Header;
